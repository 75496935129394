import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    label: String,
    loading: Boolean,
    purchasable: Boolean,
    outOfStockText: String
  }

  connect() {
    this.loading = true
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify({
        id: this.snipcartId
      })
    }
    fetch("/.netlify/functions/stock", options)
      .then((response) => response.json())
      .then((data) => {
        this.loadingValue = false
        this.purchasableValue = !!data.stock
      })
      .catch((error) => {
        this.loadingValue = false
        this.purchasableValue = false
        console.log(error)
      })
  }

  purchasableValueChanged() {
    if (this.loadingValue) return
    if (this.purchasableValue) {
      this.element.textContent = this.labelValue
      this.element.disabled = false
    } else {
      this.element.textContent = this.outOfStockTextValue || "Sold out!"
    }
  }

  get snipcartId() {
    return this.element.dataset.itemId
  }
}
