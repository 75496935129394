import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["slide"]
  static classes = [
    "load",
    "forwards",
    "backwards",
    "current",
    "next",
    "previous",
    "attemptForwards",
    "attemptBackwards"
  ]
  static values = { index: Number }

  connect() {
    this.updateState()
  }

  load() {
    this.element.classList.add(this.loadClass)
  }

  next() {
    if (this.isAtEnd) {
      this.element.classList.add(this.attemptForwardsClass)
    } else {
      this.element.classList.add(this.forwardsClass)
      this.indexValue = this.nextIndex
    }
  }

  previous() {
    if (this.isAtStart) {
      this.element.classList.add(this.attemptBackwardsClass)
    } else {
      this.element.classList.add(this.backwardsClass)
      this.indexValue = this.previousIndex
    }
  }

  updateState() {
    this.element.classList.remove(this.forwardsClass)
    this.element.classList.remove(this.backwardsClass)
    this.slideTargets.forEach((element, index) => {
      element.classList.toggle(this.currentClass, index === this.indexValue)
      element.classList.toggle(this.nextClass, index === this.nextIndex)
      element.classList.toggle(this.previousClass, index === this.previousIndex)
    })
  }

  cleanupAttempts() {
    this.element.classList.remove(this.attemptForwardsClass)
    this.element.classList.remove(this.attemptBackwardsClass)
  }

  get lastIndex() {
    return this.slideTargets.length - 1
  }

  get isAtEnd() {
    return this.indexValue === this.lastIndex
  }

  get isAtStart() {
    return this.indexValue === 0
  }

  get nextIndex() {
    if (this.isAtEnd) return
    return this.indexValue + 1
  }

  get previousIndex() {
    if (this.isAtStart) return
    return this.indexValue - 1
  }
}
